import React from "react";
import { ReactComponent as InstagramLogo } from "./assets/instagram_logo_bw.svg";
import styles from "../App.module.scss";
import ImageCarousel from "./components/Carousel";

function Home() {
  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <h1>Urjord keramik</h1>
      </header>

      <div className={styles.appBody}>
        <div className={styles.introWrapper}>
          <p>Ewelina heter jag och är verksam keramiker i Malmö.</p>
          <p>
            Jag vill skapa funktionella bruksföremål med så lite miljöpåverkan
            som möjligt. Därför jobbar jag nästan enbart med egenblandade
            glasyrer gjorda på aska.
          </p>
          <p>All keramik görs i stengodslera och kan diskas i diskmaskin. </p>
          <p>
            Jag håller keramikkurser för nybörjare och erfarna. Godkänd för
            F-skatt. Kontakta mig via mail för mer info.
          </p>
        </div>

        <div className={styles.carouselWrapper}>
          <ImageCarousel />
        </div>
      </div>

      <footer className={styles.links}>
        <div className={styles.footerTop}>
          <p>För beställningar eller frågor kontakta mig på</p>
        </div>

        <div className={styles.footerBottom}>
          <div className={styles.item}>
            <InstagramLogo className={styles.logo} />
            <a
              target="_blank"
              href={`https://www.instagram.com/urjordkeramik/`}
              rel="noreferrer"
            >
              urjordkeramik
            </a>
          </div>

          <div className={styles.item}>
            <a href={`mailto:ewelina@urjordkeramik.se`}>
              ewelina@urjordkeramik.se
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
