import React from "react";
import { Carousel } from "react-responsive-carousel";
import urjord_1 from "../assets/urjord_1.webp";
import urjord_2 from "../assets/urjord_2.webp";
import urjord_3 from "../assets/urjord_3.webp";
import urjord_4 from "../assets/urjord_4.webp";
import CarouselImage from "./CarouselImage";
import styles from "../../App.module.scss";

function ImageCarousel() {
  return (
    <Carousel
      infiniteLoop
      className={styles.carousel}
      dynamicHeight={false}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={30}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
    >
      <CarouselImage src={urjord_4} alt={"urjord 4"} />
      <CarouselImage src={urjord_3} alt={"urjord 3"} />
      <CarouselImage src={urjord_2} alt={"urjord 2"} />
      <CarouselImage src={urjord_1} alt={"urjord 1"} />
    </Carousel>
  );
}

export default ImageCarousel;
