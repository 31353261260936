import React from "react";
import styles from "../../App.module.scss";

type CarouselImageType = {
  src: string;
  alt: string;
};

function CarouselImage({ src, alt }: CarouselImageType) {
  return (
    <div className={styles.imageWrapper}>
      <img className={styles.image} src={src} alt={alt} />
    </div>
  );
}

export default CarouselImage;
